import './header.scss';

const Header = () => {
    
    return (
        <header className="header">
            <h2>Header</h2>
        </header>
    )
}

export default Header;